'use client';

import { PropsWithChildren, useMemo } from 'react';

import Image from 'next/image';

import { Wrapper } from './image.style';
import { CloudinaryCropResize, CloudinaryGravity, ObjectFit } from './types';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

import { Sizes, breakpoints } from 'styles/constants';

type Props = {
  imageId: string;
  imageSuffix: string;
  alt?: string;
  gravity: CloudinaryGravity;
  crop_resize: CloudinaryCropResize;
  aspectRatio?: string;
  priority?: boolean;
  rounded?: boolean;
  objectFit?: ObjectFit;
  withAspectRatio?: boolean;
  width?: number | null;
  height?: number | null;
};

type ImageType = 'image/jpeg' | 'image/png' | 'image/svg+xml';

const imageTypeMapper = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/svg+xml': 'svg',
};

const IMAGE_PATH = 'https://pixels-cache.icelandair.com/upload/';

const RenderImageWithAspect = ({
  children,
  dimensions,
}: PropsWithChildren & {
  dimensions?: { width: number | null | undefined; height: number | null | undefined };
}) => {
  let ratio = 16 / 9;
  if (dimensions?.width && dimensions?.height) {
    ratio = dimensions?.width / dimensions?.height;
  }
  return <AspectRatio.Root ratio={ratio}>{children}</AspectRatio.Root>;
};

export function ImageComponent({
  imageId,
  imageSuffix,
  alt,
  gravity,
  crop_resize,
  priority,
  rounded,
  objectFit,
  withAspectRatio = false,
  width,
  height,
}: Props) {
  // not 100% sure this is right.
  const imageLoader = useMemo(
    () =>
      ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
        /** NOTE: simple hack to avoid a Cloudinary error if gravity and crop are not compatible */
        if (gravity === 'g_auto' && !['c_crop', 'c_fill', 'c_lfill'].includes(crop_resize)) {
          crop_resize = 'c_fill';
        }
        let params = ['f_auto', crop_resize, gravity, `w_${width}`, `q_${quality || 'auto'}`].filter((p) => p !== null);

        return `${IMAGE_PATH}${params.join(',')}${src}`;
      },
    []
  );

  if (!imageId || !imageSuffix) return null;

  const suffix = imageTypeMapper[imageSuffix as ImageType];

  const imageSrc = `/icelandair/${imageId}.${suffix}`;

  const defaultSizes = `
  (min-width: ${breakpoints.xxl}px) ${(10 / 12) * Sizes.maxWidth}px,
  (min-width: ${breakpoints.md}px) ${Sizes.mdImageMaxWidth},
  100vw
`;

  return (
    <Wrapper>
      {!withAspectRatio ? (
        <Image
          src={imageSrc}
          alt={alt || ''}
          fill
          priority={priority}
          loader={imageLoader}
          placeholder="blur"
          style={{ objectFit: objectFit, borderRadius: rounded ? '50%' : '0%' }}
          blurDataURL={getBlurDataUrl(imageSrc, crop_resize || 'c_auto', gravity || 'g_auto')}
          sizes={defaultSizes}
        />
      ) : (
        <RenderImageWithAspect dimensions={{ width, height }}>
          <Image
            src={imageSrc}
            alt={alt || ''}
            fill
            priority={priority}
            loader={imageLoader}
            placeholder="blur"
            style={{ objectFit: objectFit, borderRadius: rounded ? '50%' : '0%' }}
            blurDataURL={getBlurDataUrl(imageSrc, crop_resize || 'c_auto', gravity || 'g_auto')}
            sizes={defaultSizes}
          />
        </RenderImageWithAspect>
      )}
    </Wrapper>
  );
}

function getBlurDataUrl(src: string, crop_resize: string, gravity: string) {
  const params = ['f_auto', crop_resize, gravity, `w_${64}`, `q_auto`, 'e_blur:400'];
  return `${IMAGE_PATH}${params.join(',')}${src}`;
}
