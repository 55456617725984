export type BreakpointKeys = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const breakpoints: Record<BreakpointKeys, number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export type GridValues = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export enum Sizes {
  maxWidth = 1440,
  mdImageMaxWidth = 1050,
  gutter = 32,
  gutterSmall = 16,
  pBottomPadding = 40,
  h3Bottom = 20,
  componentVSpace = 80,
}

let spaceRange: Record<number, number> = {};

const UNIT = 8;

Array.from({ length: 11 }).forEach((_, i) => {
  spaceRange[i] = UNIT * i;
});

const sizesObject = Object.keys(Sizes).reduce((all, key) => {
  const k = key as keyof typeof Sizes;
  const value = Sizes[k];

  if (Number(k).toString() !== k) {
    all[k] = value;
  }

  return all;
}, {} as Record<string, number>);

// TODO: Figure out what space values we need
export const spaces: Record<keyof typeof sizesObject | number, number> = {
  ...spaceRange,
  ...sizesObject,
};

export type Spaces = keyof typeof spaces;

export type GridBreakpoints = Record<BreakpointKeys, GridValues>;

export type SpaceBreakpoints = Record<BreakpointKeys, Spaces>;

export type SpaceProp = Partial<SpaceBreakpoints> | Spaces;

export type OrderProps = Record<BreakpointKeys, number>;

export type ButtonVariants = 'primary' | 'outline';

export type ButtonTypes = 'button' | 'submit' | 'reset';

export type VisibleBreakpoints = Record<BreakpointKeys, boolean>;